import React from 'react'
import { Card as CardComponent } from 'theme-ui'
import { Layout, Stack, Main, Hero } from '@layout'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import TableOfContentsExpanded from '@widgets/TableOfContentsExpanded'
import {
  PostHead,
  PostImage,
  PostBody,
  PostComments,
  PostCommentsFacebook,
  PostCommentsGraph,
  PostTagsShare,
  PostFooter
} from '@widgets/Post'
import Literature from './Literature.jsx'

const Post = ({
  data: { post, tagCategoryPosts, tagPosts, categoryPosts, previous, next },
  ...props
}) => {
  const { pageContext: { services = {}, siteUrl } = {} } = props
  
  return (
    <Layout {...props}>
      <Seo {...post} siteUrl={siteUrl} />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PostHead {...post} />
        <Divider />
      </Stack>
      <Divider space={3} />
      <Hero wide>
        <PostImage {...post} wide />
      </Hero>
      <Divider space={3} />
      <Stack effectProps={{ fraction: 0 }}>
        <Main>
          {post.tableOfContents?.items && (
            <>
              <TableOfContentsExpanded {...post} columns={1} />
              <Divider />
            </>
          )}
          <CardComponent variant='paper'>
            <PostBody {...post} />
            <Literature {...post} />
            <PostTagsShare {...post} location={props.location} />
            {services.disqus && <PostComments {...post} />}
            {services.graphComment && <PostCommentsGraph {...post} />}
            {services.facebookComment && (
              <PostCommentsFacebook {...post} siteUrl={siteUrl} />
            )}
            <PostFooter {...{ previous, next }} />
          </CardComponent>
        </Main>
      </Stack>
    </Layout>
  )
}

export default Post