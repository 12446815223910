import React from 'react'
import {Heading} from 'theme-ui'

const Literature = ({ literature }) => {
    return literature.length > 0 && 
                <div id='literaturverzeichnis'>
                    <Heading variant='h2' as='a' href='#literaturverzeichnis'>
                        LITERATURVERZEICHNIS
                    </Heading>
                    {literature.map(({ value, link }, index) => {
                        return  <div key={index} id={index+1} style={{display: "flex", alignItems: "flex-start", gap: "10px", marginBottom: "10px"}}>
                                    <p style={{margin: "0"}} >{index+1} </p>
                                    <a href={link} target='_blank' rel='noopener noreferrer' style={{color: "#718096"}}>
                                        {value}
                                    </a>
                                </div>;
                    })}
                </div>;
    }


export default Literature